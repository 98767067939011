import { FetchService } from '../../services/Api';
import { getReportName, getMonthlyReportName } from './Utils';
import html2canvas from "html2canvas";

function dataURItoBlob(dataURI) {
  const [, base64String] = dataURI.split(',');
  const bytes = atob(base64String);
  const buffer = new Uint8Array(bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    buffer[i] = bytes.charCodeAt(i);
  }
  const blob = new Blob([buffer], { type: 'image/jpeg' });
  return blob;
}

const clearReportMemory = (setLoading, reportName) => {
  let requestConf = {
    endpoint: `pizza_tv/report/generate_pdf/`,
    method: 'POST',
    data: {
      clear: 'true',
      report_name: reportName,
    }
  }
  FetchService(requestConf)
    .then(response => {
      if (response.status === 200) window.alert('PDF creation could not be carried out successfully and was interrupted')
    })
    .catch(err => console.log(`Err "${err}" occurred while trying to interrupt PDF creation`))
    .finally(() => setLoading(false))
}

const generatePDF = (report, blobs, autoPrint, afterPrint, setLoading, reportName, navigate, monthly) => {
  let requestConf2 = {
    endpoint: `pizza_tv/report/generate_pdf/`,
    method: 'POST',
    data: {
      report_id: report._id,
      report_name: reportName,
      pages_count: Object.keys(blobs).length,
      monthly: monthly? 'true': 'false'
    }
  }
  FetchService(requestConf2)
    .then(response => {
      if (response.data.msg === 'PDF uploaded to s3') {
        if (autoPrint) {
          if (afterPrint) afterPrint();
        }
        else {
          setLoading(false);
          window.alert(`PDF generado con el nombre: '${reportName}'`)
          if (monthly) navigate('/pizza-tv-month-explorer');
          else navigate('/pizza-tv-explorer');
        }
      }
      else if (response.data.msg === 'PDF generated locally') {
        setLoading(false);
        window.alert(`PDF generado localmente`)
        if (monthly) navigate('/pizza-tv-month-explorer');
        else navigate('/pizza-tv-explorer');
      }
    })
    .catch(err => {
      clearReportMemory(setLoading, reportName);
    })
}

const generatePDFWithSelenium = (report, setLoading, navigate, week_stamp) => {
  setLoading(true);
  let reportName = getReportName(new Date(report.week_stamp), report.doc.venue);
  let requestConf2 = {
    endpoint: `pizza_tv/report/generate_pdf/`,
    method: 'POST',
    data: {
      report_id: report._id,
      report_name: reportName,
      selenium: 1,
      store: report.venue,
      week_stamp: week_stamp
    }
  }
  FetchService(requestConf2)
    .then(response => {
      if (response.data.msg === 'PDF uploaded to s3') {
        window.alert(`PDF generado con el nombre: '${reportName}'`)
        navigate('/pizza-tv-explorer');
      }
      else if (response.data.msg === 'PDF generated locally') {
        window.alert(`PDF generado localmente`)
        navigate('/pizza-tv-explorer');
      }
    })
    .catch(err => {})
    .finally(() => setLoading(false))
}

const sendScreenshotsHelper = (report, blobs, autoPrint, afterPrint, setLoading, monthly, navigate, reportName, pageNum) => {
  let requestConf = {
    endpoint: `pizza_tv/report/receive_pdf_page/`,
    method: 'POST',
    data: {
      screenshot: blobs[pageNum],
      report_name: reportName,
      page_num: pageNum,
      pages_count: Object.keys(blobs).length
    }
  }
  FetchService(requestConf)
    .then(response => {
      if (pageNum === Object.keys(blobs).length - 1) {
        generatePDF(report, blobs, autoPrint, afterPrint, setLoading, reportName, navigate, monthly);
      }
      else {
        sendScreenshotsHelper(report, blobs, autoPrint, afterPrint, setLoading, monthly, navigate, reportName, pageNum + 1);
      }
    })
    .catch(error => {
      clearReportMemory(setLoading, reportName);
    });
}

const sendScreenshots = (report, blobs, autoPrint, afterPrint, setLoading, monthly, navigate) => {
  let reportName = '';
  if (monthly) reportName = getMonthlyReportName(new Date(report.month_stamp), report.doc.franchise, report.doc.region);
  else reportName = getReportName(new Date(report.week_stamp), report.doc.venue);
  sendScreenshotsHelper(report, blobs, autoPrint, afterPrint, setLoading, monthly, navigate, reportName, 0);
}

const getPagePictures = (blobs, setLoading, setBlobs, waitImagesForever = false) => {
  if (blobs !== undefined) return;
  setLoading(true);
  let pages = [...document.getElementsByClassName('pdfPage')];
  let blobsTemp = {};
  html2canvas(document.getElementById('completeReport'),
    {
      allowTaint: true,
      useCORS: true,
      logging: true,
      scale: 1,
      imageTimeout: waitImagesForever ? 0 : 15000 // 15000 ms is the default; 0 disables the timeout
    })
    .then(function (canvas) {
      let width = canvas.width;
      let height = Math.ceil(canvas.height / pages.length);
      for (let index = 0; index < pages.length; index++) {
        let pageCanvas = document.createElement('canvas');
        let pageCanvasCtx = pageCanvas.getContext('2d');
        pageCanvas.width = width;
        pageCanvas.height = height;
        pageCanvasCtx.drawImage(canvas, 0, height * index, width, height, 0, 0, width, height);
        let base64image = pageCanvas.toDataURL("image/jpeg");
        let blob = dataURItoBlob(base64image);
        blobsTemp[index] = blob;
      }
      setBlobs(blobsTemp);
    },
      function (err) {
        window.alert(`html2canvas error: ${err}`);
      })
    .catch(err => {
      window.alert(`html2canvas error2: ${err}`);
    });
}

export {
  sendScreenshots,
  getPagePictures,
  generatePDFWithSelenium
}
