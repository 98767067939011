import React, { useState, useEffect, useCallback } from 'react';
import style from './PizzaValidationPart3.module.css'
import logoBlack from '../../../../vectors/logo_black.svg';
import check from '../../../../vectors/check.svg';
import mark from '../../../../vectors/mark.svg';
import { FetchService } from '../../../../services/Api';
import totalProductionIcon from '../../../../img/total_production_icon.png';
import tipIcon from '../../../../img/tip_icon.png';
import Plot from 'react-plotly.js';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../common/loadingScreen/LoadingScreen';
import OrdinalDate from '../../../common/ordinalDate/OrdinalDate';
import firstPlace from '../../../../vectors/first-place.svg';
import secondPlace from '../../../../vectors/second-place.svg';
import thirdPlace from '../../../../vectors/third-place.svg';
import ModalWrapper from '../../../common/modalWrapper/ModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { paginator, colorVariation, PROBLEMS, SPECIALTIES_ICONS, reportPizzasToCards, DAYS, SHIFTS, COLORS, SYMBOLS } from '../../Utils';
import StepSelector from '../../common/stepSelector/StepSelector';
import { sendScreenshots, getPagePictures, generatePDFWithSelenium } from '../../PDFGeneration';
import papa_johns_logo from '../../../../img/papa_johns_logo.png';


const STORES_EVOLUTION_PAGE_MAX_STORES = 12;

const PizzaValidationPart3 = (props) => {
  const navigate = useNavigate();
  const { report, changeReportState, autoPrint, afterPrint, changeState, weekStamp, reportStructure } = props;
  const [weeklyPerformance, setWeeklyPerformance] = useState(undefined);
  const [featureBest, setFeatureBest] = useState(undefined);
  const [featureWorst, setFeatureWorst] = useState(undefined);
  const [cards, setCards] = useState([]);
  const [currentReportToStandard, setCurrentReportToStandard] = useState(undefined);
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [oldReportsToStandard, setOldReportsToStandard] = useState(undefined);
  const [ranking, setRanking] = useState([]);
  const [deltas, setDeltas] = useState({});
  const [storesEvolution, setStoresEvolution] = useState(undefined);
  const [chartsXAxis, setChartsXAxis] = useState([]);
  const [storesEvolutionWeekAvg, setStoresEvolutionWeekAvg] = useState(undefined);
  const [displayInspectedPicture, setDisplayInspectedPicture] = useState(false);
  const [inspectedPicture, setInspectedPicture] = useState(undefined);
  const [storesEvolutionOrder, setStoresEvolutionOrder] = useState(undefined);
  const [reportSpecialties, setReportSpecialties] = useState(undefined);
  const [rankingPages, setRankingPages] = useState([]);
  const [chartReady, setChartReady] = useState(false);
  const [scorelessPages, setScorelessPages] = useState([]);
  const [blobs, setBlobs] = useState(undefined);
  const [chartSemaphores, setChartSemaphores] = useState({});
  const [pizzasImages, setPizzasImages] = useState([]);
  const [imagesReady, setImagesReady] = useState(false);
  const [forcePrint, setForcePrint] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [printing, setPrinting] = useState(false);
  const [pjAverage, setPjAverage] = useState(undefined);
  const [conglomerateReady, setConglomerateReady] = useState(false);
  const [readyForSelenium, setReadyForSelenium] = useState(false);


  useEffect(() => {
    return () => {
      setDisplayInspectedPicture(false);
      setInspectedPicture(undefined);
      setPizzasImages([]);
      setImagesReady(false);
      setForcePrint(false);
      setPrinting(false);
      setTimerId(null);
      setStoresEvolution(undefined);
      setFeatureBest(undefined);
      setFeatureWorst(undefined);
      setWeeklyPerformance(undefined);
      setCurrentReportToStandard(undefined);
      setIssues([])
      setCards([]);
      setRanking([]);
      setDeltas({});
      setChartsXAxis([]);
      setReportSpecialties(undefined);
      setRankingPages([]);
      setChartReady(false);
      setLoading(false);
      setScorelessPages([]);
      setBlobs(undefined);
      setOldReportsToStandard(undefined);
      setStoresEvolutionOrder(undefined);
      setStoresEvolutionWeekAvg(undefined);
      setChartSemaphores({});
      setPjAverage(undefined);
      setConglomerateReady(false);
    }
  }, [])

  useEffect(() => {
    if (report === undefined) return;
    let d = reportPizzasToCards(report);
    setPizzasImages(Array(d.totalPizzas).fill(null).map(_ => ({ loaded: false })));
    setCards(d.cards);
    setScorelessPages(d.cardsScoreless);
    setReportSpecialties(d.specialties);
    setFeatureBest(d.featureBest);
    setFeatureWorst(d.featureWorst);
    let weekN = report.doc.week;
    let minWeek = weekN - 12;
    let weeks = [];
    let year = report.week_stamp.split('-')[0];
    for (let i = weekN - 1; i >= minWeek; i--) {
      let week = (53 + i) % 53 + 1;
      if (week === 53) year -= 1;
      weeks.unshift(`W${week} ${year}`)
    }
    setChartsXAxis(weeks);
  }, [report])

  useEffect(() => {
    if (ranking.length === 0) return;
    setRankingPages(paginator(20, 23, 7, ranking));
  }, [ranking])

  useEffect(() => {
    if (cards.length === 0 || featureWorst === undefined) return;
    let temp = [];
    for (let problem of Object.keys(PROBLEMS)) {
      let issuedPizzas = cards.filter((card) => card.standard === 'not_to_standard' && card.main_problem.includes(problem));
      temp.push({ pizzas: issuedPizzas, issue: problem });
    }
    temp.sort((a, b) => b.pizzas.length - a.pizzas.length);
    // Only keep the top 2 issues
    temp = temp.slice(0, 2);
    // Use sort stability to sort by multiple criteria, starting with the **least** important
    //  see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#sort_stability
    for (let t of temp) {
      // Pizzas with the most problems
      t.pizzas.sort((a, b) => b.main_problem.length - a.main_problem.length);
      // Pizzas not featured
      t.pizzas.sort((a, b) => a.featured === 1 && b.featured !== 1 ? 1 : (a.featured !== 1 && b.featured === 1 ? -1 : 0));
    }
    let issue1 = temp[1].issue;
    // Pizzas that do not have the other issue
    temp[0].pizzas.sort((a, b) => a.main_problem.includes(issue1) && !b.main_problem.includes(issue1) ? 1 : (!a.main_problem.includes(issue1) && b.main_problem.includes(issue1) ? -1 : 0));
    // Only keep the top 3 pizzas
    temp[0].pizzas = temp[0].pizzas.slice(0, 3);
    // Pizzas that are not selected in the other issue
    temp[1].pizzas.sort((a, b) => temp[0].pizzas.includes(a) && !temp[0].pizzas.includes(b) ? 1 : (!temp[0].pizzas.includes(a) && temp[0].pizzas.includes(b) ? -1 : 0));
    // Only keep the top 3 pizzas
    temp[1].pizzas = temp[1].pizzas.slice(0, 3);
    setIssues(temp);
  }, [cards, featureWorst])

  useEffect(() => {
    if (reportSpecialties === undefined) return;
    const weeklyPerformance = {
      total: {
        toStandard: cards.filter((card) => card.standard === 'to_standard').length,
        count: cards.length
      }
    }
    for (const specialty of reportSpecialties) {
      weeklyPerformance[specialty] = {
        toStandard: cards.filter((card) => card.specialty === specialty && card.standard === 'to_standard').length,
        count: cards.filter((card) => card.specialty === specialty).length
      }
    }
    setWeeklyPerformance(weeklyPerformance);
  }, [cards, reportSpecialties])

  useEffect(() => {
    if (report === undefined || weeklyPerformance === undefined || reportSpecialties === undefined) return;
    const currentReportToStandard = {
      total: {
        y: weeklyPerformance.total.toStandard / weeklyPerformance.total.count,
        x: `W${report.doc.week}`,
        text: `${((weeklyPerformance.total.toStandard / weeklyPerformance.total.count) * 100).toFixed(0)}%`,
      }
    }
    for (const specialty of reportSpecialties) {
      currentReportToStandard[specialty] = {
        y: (weeklyPerformance[specialty].toStandard / weeklyPerformance[specialty].count) || 0,
        x: `W${report.doc.week}`,
        text: `${(((weeklyPerformance[specialty].toStandard / weeklyPerformance[specialty].count) || 0) * 100).toFixed(0)}%`,
      }
    }
    setCurrentReportToStandard(currentReportToStandard)
  }, [weeklyPerformance, report, reportSpecialties])

  useEffect(() => {
    let requestConf = {
      endpoint: `pizza_tv/stores_evolution/`,
      method: 'GET',
      dataType: 'JSON',
      data: {
        report_id: report._id,
      },
      ...(autoPrint ? { alertErrors: false } : {})
    }

    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        setStoresEvolution(response.data);
      },
      (err) => {
        if (autoPrint) {
          if (afterPrint) afterPrint();
        }
        console.log(err);
      }
    ).catch(err => console.log(err))
    //eslint-disable-next-line
  }, [report, autoPrint])

  useEffect(() => {
    if (report === undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/conglomerate/venue_pk/${report.venue}/week_stamp/${weekStamp}/`,
      method: 'GET',
    }
    FetchService(requestConf)
      .then(response => {
        setPjAverage(response.data);
      })
      .catch(err => console.log(err))
  }, [report, weekStamp])

  useEffect(() => {
    if (storesEvolution === undefined || report === undefined || pjAverage === undefined) return;
    let deltas = {};
    let ranking = Object.keys(storesEvolution).map(storeName => storesEvolution[storeName].reports.slice(-1)[0]);
    ranking = ranking.filter(r => r.key.includes(report.doc.week));
    ranking.sort((a, b) => a.to_standard_percentage > b.to_standard_percentage ? -1 : a.to_standard_percentage < b.to_standard_percentage ? 1 : 0)
    ranking = ranking.map((item, index) => ({...item, index: index}));
    for (let i = 0; i < ranking.length; i++) {
      let delta = 0;
      if (storesEvolution[ranking[i].venue]) {
        let lastTwoReports = storesEvolution[ranking[i].venue].reports.slice(-2);
        if (lastTwoReports.length === 2) delta = Math.round(lastTwoReports[1].to_standard_percentage * 100) - Math.round(lastTwoReports[0].to_standard_percentage * 100)
      }
      deltas[ranking[i].venue] = delta;
    }
    let rankingStores = ranking.map(r => r.venue).filter(storeName => Object.keys(storesEvolution).includes(storeName));
    let sortedStoresEvolution = Object.keys(storesEvolution).sort((a, b) => storesEvolution[a].pretty_name > storesEvolution[b].pretty_name ? 1 : storesEvolution[a].pretty_name < storesEvolution[b].pretty_name ? -1 : 0);
    for (let storeName of sortedStoresEvolution) {
      if (!rankingStores.includes(storeName)) rankingStores.push(storeName);
    }
    setConglomerateReady(true);
    if (pjAverage.to_standard_percentage) {
      let i = 0;
      for (; i < ranking.length; i++) {
        if (pjAverage.to_standard_percentage > ranking[i].to_standard_percentage) break;
      }
      ranking.splice(i, 0, { to_standard_percentage: pjAverage.to_standard_percentage, pjAverage: true });
    }
    setRanking(ranking);
    setDeltas(deltas);
    setStoresEvolutionOrder(rankingStores);
  }, [storesEvolution, report, pjAverage])

  useEffect(() => {
    if (storesEvolution === undefined || report === undefined || reportSpecialties === undefined) return;
    let currentVenueReports = storesEvolution[report.venue].reports.slice(-12);
    let oldReportsToStandard = []
    for (let report of currentVenueReports.slice(0, currentVenueReports.length - 1)) {
      let elem = {
        key: report.key,
        total: report.to_standard_percentage
      }
      for (let specialty of reportSpecialties) {
        elem[specialty] = report.resume_by_specialty[specialty].to_standard_percentage;
      }
      oldReportsToStandard.push(elem);
    }
    setOldReportsToStandard(oldReportsToStandard);
  }, [report, storesEvolution, reportSpecialties])

  useEffect(() => {
    if (storesEvolution !== undefined) {
      if (autoPrint !== true) setLoading(false);
      let storesEvolutionWeekAvg = [];
      for (let key of chartsXAxis) {
        let week = key.split(' ')[0]
        let year = key.split(' ')[1]
        let avg = 0;
        let sum = 0;
        let count = 0;
        for (let storeName of Object.keys(storesEvolution)) {
          let validReports = storesEvolution[storeName].reports;
          for (let report of validReports) {
            if (report.key === week && report.week_stamp.split('-')[0] === year) {
              count += 1;
              sum += report.to_standard_percentage;
              avg = sum / count;
            }
          }
        }
        storesEvolutionWeekAvg.push(count > 0 ? avg : undefined);
      }
      setStoresEvolutionWeekAvg(storesEvolutionWeekAvg);
    }
  }, [storesEvolution, chartsXAxis, autoPrint])


  const getYValues = useCallback((reports) => {
    let validReports = reports;
    let yValues = [];
    for (let key of chartsXAxis) {
      let week = key.split(' ')[0]
      let year = key.split(' ')[1]
      let value = undefined;
      for (let report of validReports) {
        if (report.key === week && report.week_stamp.split('-')[0] === year) {
          value = report.to_standard_percentage;
          break;
        }
      }
      yValues.push(value);
    }
    return yValues;
  }, [chartsXAxis])

  const storesEvolutionTrace = (storesEvolution, chartsXAxis, storesEvolutionWeekAvg, storesEvolutionOrder) => {
    let traces = [];
    let stores = storesEvolutionOrder;
    for (let [index, store] of stores.entries()) {
      let validReports = storesEvolution[store].reports;
      for (let i = 1; i < validReports.length; i++) {
        if (chartsXAxis.map(key => key.split(' ')[0]).includes(validReports[i - 1].key) && chartsXAxis.map(key => key.split(' ')[0]).includes(validReports[i].key)) {
          traces.push({
            x: [validReports[i - 1].key, validReports[i].key],
            y: [validReports[i - 1].to_standard_percentage, validReports[i].to_standard_percentage],
            mode: 'lines',
            type: 'scatter',
            line: {
              color: COLORS[index % COLORS.length],
              width: 2
            }
          })
        }
      }
    }
    for (let i = 0, j = i + 1; j < storesEvolutionWeekAvg.length; i++, j++) {
      if (storesEvolutionWeekAvg[i] === undefined) continue;
      if (storesEvolutionWeekAvg[j] === undefined) {
        i--;
        continue;
      }
      traces.push({
        x: [chartsXAxis.map(key => key.split(' ')[0])[i], chartsXAxis.map(key => key.split(' ')[0])[j]],
        y: [storesEvolutionWeekAvg[i], storesEvolutionWeekAvg[j]],
        mode: 'lines',
        type: 'scatter',
        line: {
          dash: 'dash',
          color: 'black',
          width: 3
        },
      })
      i = j - 1
      j = i + 1;
    }
    return traces;
  }

  useEffect(() => {
    if (blobs === undefined) return;
    sendScreenshots(report, blobs, autoPrint, afterPrint, setLoading, undefined, navigate);
    // eslint-disable-next-line
  }, [blobs, autoPrint, report, navigate])

  useEffect(() => {
    if (printing) return;
    if (autoPrint && conglomerateReady && ((chartReady && imagesReady) || forcePrint)) {
      setPrinting(true);
      getPagePictures(blobs, setLoading, setBlobs, !forcePrint);
    }
  }, [autoPrint, chartReady, blobs, imagesReady, forcePrint, printing, conglomerateReady])

  const registerSemaphore = useCallback((name) => {
    chartSemaphores[name] = false
  }, [chartSemaphores])

  const checkSemaphores = useCallback((name) => {
    if (storesEvolutionWeekAvg === undefined || oldReportsToStandard === undefined || storesEvolutionOrder === undefined || chartSemaphores === undefined || reportSpecialties === undefined || chartSemaphores[name]) return;
    chartSemaphores[name] = true
    if (Object.keys(chartSemaphores).length < reportSpecialties.length + 2) return;
    for (let key of Object.keys(chartSemaphores)) {
      if (chartSemaphores[key] === false) return;
    }
    setChartReady(true);
  }, [chartSemaphores, storesEvolutionWeekAvg, oldReportsToStandard, storesEvolutionOrder, reportSpecialties])

  const handleImageLoad = useCallback((index) => {
    setPizzasImages(prevData => {
      return prevData.map((obj, idx) => {
        if (idx === index) return { loaded: true };
        return { ...obj };
      })
    })
  }, [])

  useEffect(() => {
    if (pizzasImages.length === 0) return;
    for (let pizza of pizzasImages) {
      if (!pizza.loaded) return;
    }
    setImagesReady(true);
  }, [pizzasImages])

  useEffect(() => {
    if (autoPrint && !imagesReady) {
      const id = setTimeout(() => {
        if (!imagesReady && !printing) {
          // window.alert(`Warning: ${report.doc.venue} report will be generated with some unloaded images.`);
          setForcePrint(true);
        }
      }, 30000);
      setTimerId(id);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
    // eslint-disable-next-line
  }, [imagesReady]);

  useEffect(() => {
    if (conglomerateReady && chartReady && imagesReady) {
      setReadyForSelenium(true);
    }
  }, [chartReady, imagesReady, conglomerateReady])

  return (
    <LoadingScreen loading={loading}>
      {
        readyForSelenium ? (
          <div id='readyForSelenium' style={{display: 'none'}}></div>
        ) : (
          null
        )
      }
      {
        displayInspectedPicture ? (
          <ModalWrapper
            allScreen={true}
            fitContent={true}
            display={displayInspectedPicture}
            setDisplay={setDisplayInspectedPicture} >
            <div className={style.inspectedPictureWrapper}>
              <img className={style.inspectedPicture} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${inspectedPicture}`} alt='' />
              <div className={style.closeInspectedPictureButton} onClick={() => setDisplayInspectedPicture(false)}><span><FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'black' }} icon={faPlus} /></span></div>
            </div>
          </ModalWrapper>
        ) : (
          null
        )
      }
      <div>
        {
          props.editable !== false ? (
            <span className={style.reportTitle}>Report Review - Store {report.doc.venue}</span>
          ) : (
            null
          )
        }
        {
          props.editable !== false ? (
            <StepSelector report={report} callback={changeState} beforeChangeState={() => { setLoading(true) }} errCallback={() => { setLoading(false) }}></StepSelector>
          ) : (
            null
          )
        }
        {
          props.editable !== false ? (
            <div className={style.flowButtons}>
              <button className={style.flowButton} onClick={() => changeReportState('prev')}>{'< Back'}</button>
              <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => getPagePictures(blobs, setLoading, setBlobs, false)}>{'Generate File'}</button>
              <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => generatePDFWithSelenium(report, setLoading, navigate, weekStamp)}>{'Generate File (Selenium)'}</button>
              <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => navigate('/pizza-tv-explorer')}>{'Finish'}</button>
            </div>
          ) : (
            null
          )
        }
        {/*
        // Cover with background image
        <div className={`${style.reportPage} pdfPage`} style={{ padding: 'inherit', backgroundImage: `url(${cover})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
        */}
        <div id='completeReport'>
          <div className={`${style.reportPage} pdfPage`} style={{ padding: 'inherit' }}>
            <img className={style.coverBrand} src={logoBlack} alt='kwali' />
            <div className={style.coverInfo}>
              <span className={style.coverInfoTitle}>Weekly Pizza Quality Report</span>
              <div className={style.coverInfoDescription}>
                <span>{report.doc.franchise}</span>
                <span>{`Store: ${report.doc.venue}`}</span>
                <span>{`Week ${report.doc.week} - Report ${report.doc.number}`}</span>
                <span><OrdinalDate date={new Date(report.week_stamp)} /> - <OrdinalDate date={new Date(new Date(report.week_stamp).getTime() + 6 * 24 * 60 * 60 * 1000)} />, {new Date(report.week_stamp).getFullYear()}</span>
              </div>
            </div>
          </div>
          <div className={`${style.reportPage} pdfPage`}>
            <span className={style.pageTitle}>Weekly Performance</span>
            <span className={style.disclaimer}>Insights from 250 Pizzas per week</span>
            {
              weeklyPerformance !== undefined && Object.keys(weeklyPerformance).length > 0 ? (
                <table className={style.weeklyPerformanceTable}>
                  <tbody>
                    <tr>
                      <td></td>
                      <td className={style.bordered} style={{ padding: '1% 0', fontSize: '30px' }}>Pizzas To Standard</td>
                    </tr>
                    <tr>
                      <td className={`${style.main} ${style.firstCol}`}>Total Production</td>
                      <td className={`${style.main} ${style.bordered}`}>{`${((weeklyPerformance.total.toStandard / weeklyPerformance.total.count) * 100).toFixed(0)}%`}</td>
                    </tr>
                    {
                      reportSpecialties.map((specialty, index) => {
                        return <tr key={index}>
                          <td className={style.firstCol}>
                            <div>
                              <img src={SPECIALTIES_ICONS[specialty]} alt='Pizza' />
                              <span>{reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty] ? (reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty].ui_text ?? specialty) : specialty}</span>
                            </div>
                          </td>
                          <td className={style.bordered}>{`${(((weeklyPerformance[specialty].toStandard / weeklyPerformance[specialty].count) || 0) * 100).toFixed(0)}%`}</td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              ) : (
                null
              )
            }
            <div className={style.features}>
              {
                featureBest ? (
                  <div className={style.feature}>
                    <img onClick={() => { setInspectedPicture(featureBest.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${featureBest.img_url}`} alt='Best Pizza' />
                    <span className={style.cardID}>{featureBest.cardIndex}</span>
                    <div className={style.featureInfo}>
                      <div className={style.featureLegend}>{`${featureBest.weekday.slice(0, 3)} - ${featureBest.time_stamp || (featureBest.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                      <img src={check} alt='best' className={style.featureIcon} />
                    </div>
                  </div>

                ) : (
                  null
                )
              }
              {
                featureWorst ? (
                  <div className={style.feature}>
                    <img onClick={() => { setInspectedPicture(featureWorst.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${featureWorst.img_url}`} alt='Worst Pizza' />
                    <span className={style.cardID}>{featureWorst.cardIndex}</span>
                    <div className={style.featureInfo}>
                      <div className={style.featureLegend}>{`${featureWorst.weekday.slice(0, 3)} - ${featureWorst.time_stamp || (featureWorst.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                      <img src={mark} alt='worst' className={style.featureIcon} />
                    </div>
                  </div>
                ) : (
                  null
                )
              }
            </div>
            <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
          </div>
          <div className={`${style.reportPage} pdfPage`}>
            <span className={style.pageTitle}>Tips to Improve Your Pizzas</span>
            {
              issues.length > 0 ? (
                <div>
                  <div className={style.tipBlock}>
                    <div className={style.tipTitle}>
                      <img className={style.tipIcon} src={tipIcon} alt='tip' />
                      <span className={style.tipText}>{PROBLEMS[issues[0].issue].tip.en}</span>
                    </div>
                    <div className={style.issues}>
                      {
                        issues[0].pizzas.map((pizza, index) => {
                          return (
                            <div className={style.issue} key={index}>
                              <img onClick={() => { setInspectedPicture(pizza.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${pizza.img_url}`} alt='Pizza' />
                              <span className={style.cardID}>{pizza.cardIndex}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  {
                    issues[1].pizzas.length > 0 ? (
                      <div className={style.tipBlock}>
                        <div className={style.tipTitle}>
                          <img className={style.tipIcon} src={tipIcon} alt='tip' />
                          <span className={style.tipText}>{PROBLEMS[issues[1].issue].tip.en}</span>
                        </div>
                        <div className={style.issues}>
                          {
                            issues[1].pizzas.map((pizza, index) => {
                              return (
                                <div className={style.issue} key={index}>
                                  <img onClick={() => { setInspectedPicture(pizza.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${pizza.img_url}`} alt='Pizza' />
                                  <span className={style.cardID}>{pizza.cardIndex}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>
              ) : (
                null
              )
            }
            <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
          </div>
          <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }}>
            <span className={style.pageTitle}>Last Week’s Production</span>
            <div className={style.cards}>
              {
                cards.map((card, index) => {
                  return (
                    <div className={style.card} key={index}>
                      <img onLoad={() => handleImageLoad(card.cardIndex - 1)} onClick={() => { setInspectedPicture(card.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.img_url}`} alt='Pizza' />
                      <span className={style.cardID}>{card.cardIndex}</span>
                      <div className={style.cardInfo}>
                        <div className={style.cardLegend}>{`${card.weekday.slice(0, 3)} - ${card.timeStamp || (card.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                        <img src={card.standard === 'to_standard' ? check : mark} alt='Pizza' className={style.cardIcon} />
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
          </div>
          <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }}>
            <span className={style.pageTitle}>Pizzas To Standard Trend</span>
            <div className={style.chartsWrapper} style={{ marginTop: `${100 * (1 / (((reportSpecialties || []).length + 1) / 3))}px` }}>
              {
                currentReportToStandard !== undefined ? (
                  <div className={style.charts} style={{ gap: `${50 * (1 / ((reportSpecialties.length + 1) / 3))}px` }}>
                    {
                      reportSpecialties.map((specialty, index) => {
                        return <div className={style.specialtyChart} key={index}>
                          <div className={style.chartLegend}>
                            <img src={SPECIALTIES_ICONS[specialty]} alt='Pizza' />
                            <span>{reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty] ? (reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty].ui_text ?? specialty) : specialty}<br />To Standard</span>
                          </div>
                          <div className={style.plot}>
                            <Plot
                              onInitialized={() => registerSemaphore(`${specialty}_tostandard`)}
                              onUpdate={() => checkSemaphores(`${specialty}_tostandard`)}
                              data={[
                                {
                                  type: 'bar',
                                  x: (oldReportsToStandard ?? []).map((report) => report.key),
                                  y: (oldReportsToStandard ?? []).map((report) => report[specialty]),
                                  textposition: 'outside',
                                  text: (oldReportsToStandard ?? []).map((report) => `<span style="font-weight: bold">${(((report[specialty]) || 0) * 100).toFixed(0)}%</span>`),
                                  name: `${specialty} All Types`,
                                  marker: {
                                    color: 'white',
                                    line: {
                                      color: 'black',
                                      width: 2
                                    }
                                  },
                                  textfont: {
                                    size: 25,
                                  }
                                },
                                {
                                  type: 'bar',
                                  x: [currentReportToStandard[specialty].x],
                                  y: [currentReportToStandard[specialty].y],
                                  textposition: 'outside',
                                  text: [`<span style="font-weight: bold">${currentReportToStandard[specialty].text}</span>`],
                                  name: `${specialty} All Types`,
                                  marker: {
                                    color: '#4472c4',
                                    line: {
                                      color: 'black',
                                      width: 2
                                    }
                                  },
                                  textfont: {
                                    size: 25,
                                  }
                                },
                              ]}
                              layout={{
                                height: 550 * (1 / ((reportSpecialties.length + 1) / 3)),
                                autosize: true,
                                margin: {
                                  t: 80 * (1 / ((reportSpecialties.length + 1) / 3)),
                                  l: 80,
                                  r: 40,
                                  b: 80 * (1 / ((reportSpecialties.length + 1) / 3))
                                },
                                yaxis: {
                                  automargin: true,
                                  tickformat: ',.0%',
                                  showline: false,
                                  showticklabels: true,
                                  showgrid: false,
                                  zeroline: true,
                                  range: [0, 1.10],
                                  rangemode: 'tozero',
                                  tickfont: {
                                    size: 25
                                  }
                                },
                                xaxis: {
                                  showgrid: false,
                                  tickfont: {
                                    size: 25
                                  }
                                },
                                paper_bgcolor: 'rgba(255,255,255,1)',
                                plot_bgcolor: 'rgba(255,255,255,1)',
                                showlegend: false,

                              }}
                              config={{ displayModeBar: false }}
                              useResizeHandler={true}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>
                      })
                    }
                    <div className={style.specialtyChart}>
                      <div className={style.chartLegend}>
                        <img src={totalProductionIcon} alt='Pizza' />
                        <span>Total Production<br />To Standard</span>
                      </div>
                      <div className={style.plot}>
                        <Plot
                          onInitialized={() => registerSemaphore(`total_tostandard`)}
                          onUpdate={() => checkSemaphores(`total_tostandard`)}
                          data={[
                            {
                              type: 'bar',
                              x: (oldReportsToStandard ?? []).map((report) => report.key),
                              y: (oldReportsToStandard ?? []).map((report) => report.total),
                              textposition: 'outside',
                              text: (oldReportsToStandard ?? []).map((report) => `<span style="font-weight: bold">${(((report.total) || 0) * 100).toFixed(0)}%</span>`),
                              name: 'Total Production',
                              marker: {
                                color: 'white',
                                line: {
                                  color: 'black',
                                  width: 2
                                }
                              },
                              textfont: {
                                size: 25,
                              }
                            },
                            {
                              type: 'bar',
                              x: [currentReportToStandard.total.x],
                              y: [currentReportToStandard.total.y],
                              textposition: 'outside',
                              text: [`<span style="font-weight: bold">${currentReportToStandard.total.text}</span>`],
                              name: 'Total Production',
                              marker: {
                                color: '#4472c4',
                                line: {
                                  color: 'black',
                                  width: 2
                                }
                              },
                              textfont: {
                                size: 25,
                              }
                            },
                          ]}
                          layout={{
                            height: 550 * (1 / ((reportSpecialties.length + 1) / 3)),
                            autosize: true,
                            margin: {
                              t: 80 * (1 / ((reportSpecialties.length + 1) / 3)),
                              l: 80,
                              r: 40,
                              b: 80 * (1 / ((reportSpecialties.length + 1) / 3))
                            },
                            yaxis: {
                              automargin: true,
                              tickformat: ',.0%',
                              showline: false,
                              showticklabels: true,
                              showgrid: false,
                              zeroline: true,
                              range: [0, 1.10],
                              rangemode: 'tozero',
                              tickfont: {
                                size: 25
                              }
                            },
                            xaxis: {
                              showgrid: false,
                              tickfont: {
                                size: 25
                              }
                            },
                            paper_bgcolor: 'rgba(255,255,255,1)',
                            plot_bgcolor: 'rgba(255,255,255,1)',
                            showlegend: false,

                          }}
                          config={{ displayModeBar: false }}
                          useResizeHandler={true}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>

                  </div>
                ) : (
                  null
                )
              }
            </div>
            <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
          </div>
          {
            rankingPages.map((ranking, index) => {
              return <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} key={index}>
                {
                  index === 0 ? (
                    <React.Fragment>
                      <span className={style.pageTitle}>Weekly Quality Ranking – Pizzas To Standard</span>
                      {
                        pjAverage && pjAverage.to_standard_percentage? (
                          <span className={style.disclaimer}>Papa John's average for stores with over three months of data</span>
                        ):(
                          null
                        )
                      }
                    </React.Fragment>
                  ) : (
                    null
                  )
                }
                <div className={style.ranking}>
                  <span className={style.deltaTitle}>∆ vs Last report</span>
                  {
                    ranking.map((r, index2) => {
                      return r.pjAverage ?
                        <div key={index2} className={`${style.rankingRow} ${index === 0 && r.index < 3 ? style.podiumRow : null}`} style={index === 0 && r.index === 2 ? { marginBottom: '80px' } : null}>
                          <span style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                              <img style={{ width: '200px' }} src={papa_johns_logo} alt='Papa Johns' /><span>Average</span>
                            </div>
                          </span>
                          <div className={style.rankingBar}>
                            <span className={style.percentageBar} style={{ width: `${(r.to_standard_percentage) * 75}%`, borderColor: '#089675', backgroundColor: '#089675' }}></span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ color: 'black' }}>{`${(r.to_standard_percentage * 100).toFixed(0)}%`}</span>
                            </div>
                          </div>
                          <span className={style.deltaZero} style={{ opacity: '0' }}>0</span>
                        </div>
                        :
                        <div key={index2} className={`${style.rankingRow} ${index === 0 && r.index < 3 ? style.podiumRow : null} ${r.venue === report.venue ? style.storeRow : null}`} style={index === 0 && r.index === 2 ? { marginBottom: '80px' } : null}>
                          <span>{r.doc.venue}</span>
                          <div className={style.rankingBar}>
                            <span className={style.percentageBar} style={{ width: `${(r.to_standard_percentage) * 75}%` }}></span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span>{`${(r.to_standard_percentage * 100).toFixed(0)}%`}</span>
                              {
                                index === 0 && r.index < 3 ? (
                                  <img src={r.index === 0 ? firstPlace : r.index === 1 ? secondPlace : r.index === 2 ? thirdPlace : null} alt='' className={style.podiumIcon} />
                                ) : (
                                  null
                                )
                              }
                            </div>
                          </div>
                          {
                            Object.keys(deltas).length !== 0 ? (
                              <span className={deltas[r.venue] > 0 ? style.deltaPositive : deltas[r.venue] < 0 ? style.deltaNegative : style.deltaZero}>{`${deltas[r.venue] > 0 ? '+' : deltas[r.venue] < 0 ? '-' : ''}${Math.abs(deltas[r.venue])}%`}</span>
                            ) : (
                              null
                            )
                          }
                        </div>
                    })
                  }
                </div>
                <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
              </div>
            })
          }
          <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }}>
            <span className={style.pageTitle}>Stores Evolution</span>
            <span className={style.disclaimer}>(% Pizzas To Standard | Chart)</span>
            <div className={style.chartsWrapper} style={{ marginTop: '20px' }}>
              <div className={style.charts}>
                <div className={style.storesEvolutionChart} style={{ paddingLeft: Object.keys((storesEvolutionOrder ?? [])).length > STORES_EVOLUTION_PAGE_MAX_STORES ? '0' : `300px` }}>
                  <div className={style.plot} style={{ width: '100%', border: 'none' }}>
                    <Plot
                      onInitialized={() => registerSemaphore(`storesevolution`)}
                      onUpdate={() => checkSemaphores(`storesevolution`)}
                      data={
                        (storesEvolutionOrder ?? []).map((storeName, index) => {
                          return {
                            type: 'scatter',
                            x: chartsXAxis.map(key => key.split(' ')[0]),
                            y: getYValues(storesEvolution[storeName].reports),
                            text: getYValues(storesEvolution[storeName].reports).map((toStandardPercentage) => `<span style="font-weight: bold">${(((toStandardPercentage) || 0) * 100).toFixed(0)}%</span>`),
                            name: storesEvolution[storeName].pretty_name,
                            mode: 'markers',
                            marker: {
                              symbol: SYMBOLS[index % SYMBOLS.length],
                              size: 10,
                              color: COLORS[index % COLORS.length],
                              line: {
                                color: COLORS[index % COLORS.length],
                                width: 2
                              }
                            },
                            textfont: {
                              size: 25,
                            }
                          }
                        }).concat([{
                          type: 'scatter',
                          x: chartsXAxis.map(key => key.split(' ')[0]),
                          y: (storesEvolutionWeekAvg ?? []),
                          text: (storesEvolutionWeekAvg ?? []).map((toStandardPercentage) => `<span style="font-weight: bold">${(((toStandardPercentage) || 0) * 100).toFixed(0)}%</span>`),
                          name: 'Weekly Average',
                          mode: 'markers',
                          marker: {
                            size: 3,
                            color: 'black',
                          },
                          textfont: {
                            size: 25,
                          }
                        }]).concat(storesEvolutionTrace(storesEvolution, chartsXAxis, (storesEvolutionWeekAvg ?? []), (storesEvolutionOrder ?? [])))
                      }
                      layout={{
                        height: Object.keys((storesEvolutionOrder ?? [])).length > STORES_EVOLUTION_PAGE_MAX_STORES ? 1800 : 1400,
                        autosize: true,
                        margin: {
                          t: 80,
                          l: 80,
                          r: 0,
                          b: 40
                        },
                        yaxis: {
                          automargin: true,
                          tickformat: ',.0%',
                          showline: false,
                          showticklabels: true,
                          showgrid: false,
                          zeroline: true,
                          range: [0, 1.05],
                          rangemode: 'tozero',
                          tickfont: {
                            size: 25
                          }
                        },
                        xaxis: {
                          showgrid: false,
                          tickfont: {
                            size: 25
                          }
                        },
                        paper_bgcolor: 'rgba(255,255,255,1)',
                        plot_bgcolor: 'rgba(255,255,255,1)',
                        showlegend: false,

                      }}
                      config={{ displayModeBar: false }}
                      useResizeHandler={true}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingRight: '14px' }}>
              {
                Object.keys((storesEvolutionOrder ?? [])).length > STORES_EVOLUTION_PAGE_MAX_STORES ? (
                  null
                ) : (
                  <table className={style.storesEvolutionTable}>
                    <tbody>
                      <tr>
                        <td> <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px'
                        }}><span style={{
                          background: 'linear-gradient(to right, black 0px, black 25%, white 25%, white 37.5%, black 37.5%, black 62.5%, white 62.5%, white 75%, black 75%, black 100%)',
                          width: '45px',
                          height: '5px',
                          display: 'block',
                        }}></span>Weekly Average</div></td>
                        {
                          (storesEvolutionWeekAvg ?? []).map((val, index) => {
                            return <td key={index} style={{ backgroundColor: colorVariation(storesEvolutionWeekAvg ?? [], index) }}>{val !== undefined ? `${(val * 100).toFixed(0)}%` : ''}</td>
                          })
                        }
                      </tr>
                      {
                        (storesEvolutionOrder ?? []).map((storeName, index) => {
                          return <tr key={index}>
                            <td> <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                              fontWeight: `${storeName === report.venue ? 'bold' : 'normal'}`
                            }}><Plot
                                data={[
                                  {
                                    x: [0],
                                    y: [0],
                                    mode: 'markers',
                                    marker: {
                                      symbol: SYMBOLS[index % SYMBOLS.length],
                                      size: 10,
                                      color: COLORS[index % COLORS.length],
                                      line: {
                                        width: 2,
                                        color: COLORS[index % COLORS.length],
                                      },
                                    },
                                  }
                                ]}
                                layout={{
                                  width: 20,
                                  height: 20,
                                  showlegend: false,
                                  xaxis: {
                                    showgrid: false,
                                    zeroline: false,
                                    showticklabels: false
                                  },
                                  yaxis: {
                                    showgrid: false,
                                    zeroline: false,
                                    showticklabels: false
                                  },
                                  margin: { l: 0, r: 0, t: 0, b: 0 },
                                }}
                                config={{ displayModeBar: false }}
                              /><span style={{
                                backgroundColor: COLORS[index % COLORS.length],
                                width: '40px',
                                height: '5px',
                                display: 'block',
                                marginLeft: '-20px'
                              }}></span>{storesEvolution[storeName].pretty_name}</div></td>
                            {
                              getYValues(storesEvolution[storeName].reports).map((val, index2) => {
                                return <td key={index2} style={{ backgroundColor: colorVariation(getYValues(storesEvolution[storeName].reports), index2) }}>{val !== undefined ? `${(val * 100).toFixed(0)}%` : ''}</td>
                              })
                            }
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                )
              }
            </div>
            <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
          </div>
          {
            Object.keys((storesEvolutionOrder ?? [])).length > STORES_EVOLUTION_PAGE_MAX_STORES ? (
              <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} >
                <span className={style.disclaimer}>(% Pizzas to standard | Table)</span>
                <br />
                <br />
                <div>
                  <table className={style.storesEvolutionTable}>
                    <tbody>
                      <tr>
                        <td></td>
                        {
                          chartsXAxis.map(key => key.split(' ')[0]).map((week, index) => {
                            return <td key={index}>{week}</td>
                          })
                        }
                      </tr>
                      <tr>
                        <td> <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px'
                        }}><span style={{
                          background: 'linear-gradient(to right, black 0px, black 25%, white 25%, white 37.5%, black 37.5%, black 62.5%, white 62.5%, white 75%, black 75%, black 100%)',
                          width: '45px',
                          height: '5px',
                          display: 'block',
                        }}></span>Weekly Average</div></td>
                        {
                          (storesEvolutionWeekAvg ?? []).map((val, index) => {
                            return <td key={index} style={{ backgroundColor: colorVariation(storesEvolutionWeekAvg ?? [], index) }}>{val !== undefined ? `${(val * 100).toFixed(0)}%` : ''}</td>
                          })
                        }
                      </tr>
                      {
                        (storesEvolutionOrder ?? []).map((storeName, index) => {
                          return <tr key={index}>
                            <td> <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                              fontWeight: `${storeName === report.venue ? 'bold' : 'normal'}`
                            }}><Plot
                                data={[
                                  {
                                    x: [0],
                                    y: [0],
                                    mode: 'markers',
                                    marker: {
                                      symbol: SYMBOLS[index % SYMBOLS.length],
                                      size: 10,
                                      color: COLORS[index % COLORS.length],
                                      line: {
                                        width: 2,
                                        color: COLORS[index % COLORS.length],
                                      },
                                    },
                                  }
                                ]}
                                layout={{
                                  width: 20,
                                  height: 20,
                                  showlegend: false,
                                  xaxis: {
                                    showgrid: false,
                                    zeroline: false,
                                    showticklabels: false
                                  },
                                  yaxis: {
                                    showgrid: false,
                                    zeroline: false,
                                    showticklabels: false
                                  },
                                  margin: { l: 0, r: 0, t: 0, b: 0 },
                                }}
                                config={{ displayModeBar: false }}
                              /><span style={{
                                backgroundColor: COLORS[index % COLORS.length],
                                width: '40px',
                                height: '5px',
                                display: 'block',
                                marginLeft: '-20px'
                              }}></span>{storesEvolution[storeName].pretty_name}</div></td>
                            {
                              getYValues(storesEvolution[storeName].reports).map((val, index2) => {
                                return <td key={index2} style={{ backgroundColor: colorVariation(getYValues(storesEvolution[storeName].reports), index2) }}>{val !== undefined ? `${(val * 100).toFixed(0)}%` : ''}</td>
                              })
                            }
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
              </div>
            ) : (
              null
            )
          }
          {
            report.standard_scoreless_report === 1 ? (
              scorelessPages.map((scoreless, index0) => {
                return (
                  <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} key={index0}>
                    {
                      index0 === 0 ? (
                        <React.Fragment>
                          <span className={style.pageTitle}>Last Week’s Production (Scoreless)</span>
                        </React.Fragment>
                      ) : (
                        null
                      )
                    }
                    {
                      Object.keys(scoreless).length > 0 ? (
                        Object.keys(scoreless).sort((a, b) => DAYS.indexOf(a) - DAYS.indexOf(b)).map((day, index1) => {
                          return (
                            <div key={index1}>
                              {
                                Object.keys(scoreless[day]).sort((a, b) => SHIFTS.indexOf(a) - SHIFTS.indexOf(b)).map((shift, index2) => {
                                  return (
                                    <div key={index2}>
                                      <span style={{
                                        border: shift === 'PM' ? '3px solid #4472c4' : '3px solid #f6c161',
                                        borderRadius: '50px',
                                        padding: '15px 0',
                                        fontSize: '30px',
                                        textAlign: 'center',
                                        marginBottom: '25px',
                                        marginTop: '25px',
                                        display: 'block',
                                        width: '400px'
                                      }}>{`${day} - ${shift}`}</span>
                                      <div className={style.shiftedCards}>
                                        {
                                          scoreless[day][shift].map((card, index3) => {
                                            return (
                                              <div className={style.card} key={index3}>
                                                <img onLoad={() => handleImageLoad(card.cardIndex - 1)} onClick={() => { setInspectedPicture(card.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.img_url}`} alt='Pizza' />
                                                <span className={style.cardID}>{card.cardIndex}</span>
                                                <div className={style.cardInfo}>
                                                  <div className={style.cardLegend}>{`${card.weekday.slice(0, 3)} - ${card.timeStamp || (card.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      ) : (
                        null
                      )
                    }
                    <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                  </div>
                )
              })
            ) : (
              null
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default PizzaValidationPart3;